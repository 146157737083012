<script lang="ts" setup>
import { MediaImageFragment } from '~/api/graphql/generated'

const props = defineProps<{
  image: string | MediaImageFragment
}>()

const isString = typeof props.image === 'string'
</script>

<template>
  <SidebarSection>
    <img
      v-if="isString"
      v-animate="{ animation: 'vertical-reveal' }"
      :src="image as string"
      alt="Careum Weiterbildung"
    />
    <Image
      v-else
      v-animate="{ animation: 'vertical-reveal' }"
      :entity="image as MediaImageFragment"
      type="sidebar-logo"
    />
  </SidebarSection>
</template>

<style lang="scss" scoped>
img {
  width: 100%;
  max-width: 105px;
  height: auto;

  @include breakpoint(tl) {
    max-width: 140px;
  }
}
</style>
